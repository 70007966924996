<template>
    <div class="itemHolder">
        <img v-if="itemNew" class="tag" src="@/assets/new-tag.png">
        <img :src="getImage('zeleny_caj')">
        <h2>{{ itemName }}</h2>
        <i>{{ itemDescription.substring(0,18) }} ...</i>
        <p v-if="itemStock > 0" class="av-display" style="color:green">Skladem</p>
        <p v-else class="av-display" style="color:red">Není na skladě</p>
        <div class="lowerHolder">
            <p id="priceTag"> {{ itemPrice }} Kč/g </p>
            <RouterLink :to="{ name: 'ItemSiteViewState', params: { productId: itemId, amm: Ammount } }" id="buyBut">
                Přidat do košíku
            </RouterLink>
            <div id="AddDrop">
                <div class="manage">
                    <svg v-on:click="() => {if(this.Ammount > 50){this.Ammount -= 50}}" class="text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 12h14"/>
                    </svg>
                    <h1>{{ Ammount }}g</h1>
                    <svg v-on:click="() => {if(this.Ammount < 500){this.Ammount += 50}}" class="text-gray-800 dark:text-white svg-r" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 12h14m-7 7V5"/>
                    </svg>
                </div>

            </div>
        </div>
    </div>
</template>

<script>

export default {
name: 'ItemSmallView',
props: {
  itemName: String,
  itemPrice: Number,
  itemDescription: String,
  itemStock: Number,
  itemNew: Boolean,
  itemId: Number,
},
data(){
    return{
        Ammount: 50,
    }
},
methods: {
    getImage(img){
        return require(`@/assets/${img}.png`)
    }
}
}
</script>


<style scoped>

    #AddDrop{
        left:0;
        width: 128.4px;
        position: absolute;
        background-color: rgba(0, 0, 0, 0.8);
        transform: translate(131.6px, 18px);
        display: none;
        z-index: 1;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        height:60px;
        color: white;
        font-size: 0.7rem;
    }

    .manage svg{
        padding-top: 9px;
        cursor: pointer;
        user-select: none;
    }

    .manage svg:active{
        color:rgba(255, 255, 255, 0.5);
    }

    .manage{
        padding-top: 18px;
        display: inline-flex;
        justify-content: space-around;
        width: 100%;
    }

    #AddDrop h1{
        text-align: center;
        font-weight: 400;
        font-size: 1rem;
        width: 35px;
    }

    #priceTag{
        font-size: 1rem;
        color: black;
    }

    #buyBut{
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: black;
        color: white;
        width: 160px;
        height: 40px;
        border: none;
        border-radius: 60px;
        cursor: pointer;
        margin-right: 1.2rem;
        font-weight: 600;
        font-size: 0.8rem;
        text-decoration: none;
        transition: 0.2s;
        z-index: 3;
    }


    #buyBut:hover{
        background-color: rgb(23, 23, 23);
    }

    #buyBut:hover + #AddDrop, #AddDrop:hover{
        display: block;
    }

    .tag{
        position: absolute;
        width: 4.5rem;
        height: 4.5rem;
    }

    .av-display{
        font-weight: 600;
        width: 100%;
        margin-bottom: 0.2rem;
        margin-top: 0.4rem;
        font-size: 0.9rem;
    }

    .color-red{
        color:red;
        cursor: pointer;
        transition:0.1s;
    }

    .color-green{
        color:green;
        cursor: pointer;
        transition:0.1s;
    }

    .color-green:hover{
        color:rgba(0, 128, 0, 0.7);
    }

    .color-red:hover{
        color:rgba(255, 0, 0, 0.7);
    }

    img{
        display: block;
        width: 240px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 0.6rem;
        margin-bottom: 0.8rem;
    }

    h2{
        margin-top: 0.5rem;
        margin-left: 1.1rem;
        margin-bottom: 0.5rem;
        color:black;
        font-size: 1.2rem;
    }

    i{
        margin-left: 1.2rem;
        font-size: 0.8rem;
    }

    p{
        margin-left: 1.2rem;
        font-size: 0.9rem;
        font-weight: 600;
        width: 50%;
    }

    .itemHolder{
        width: 280px;
        height: 395px;
        background-color: rgb(250, 250, 250);
        box-shadow: 0px 3px 3px 3px rgba(0, 0, 0, 0.2);
        margin:10px;
        transition: 0.2s;
        border-radius: 25px;
    }

    .lowerHolder{
        display: inline-flex;
        width:100%;
    }

    .itemHolder:hover{
        transform: translateY(-3px);
        box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.25);
        background-color: white;
    }

    @media screen and (max-width: 1200px) {
    .itemHolder{
      width: 205px;
      height: 300px;
    }
    img{
        width: 150px;
    }
    #priceTag{
        font-size: 0.8rem;
        color: black;
    }
    #buyBut{
        width: 120px;
        height: 25px;
        margin-top: 8px;
        font-weight: 400;
        font-size: 0.6rem;
    }
    #buyBut:hover + #AddDrop, #AddDrop:hover{
        display: none;
    }
    
    }
    @media screen and (max-width: 900px) {
        .itemHolder{
            width: 280px;
            height: 395px;
    }
    img{
        width: 230px;
    }
    #priceTag{
        font-size: 1rem;
        color: black;
    }
    #buyBut{
        width: 160px;
        height: 40px;
        font-weight: 600;
        font-size: 0.8rem;
    }

    }
    @media screen and (max-width: 600px) {
    .itemHolder{
      width: 205px;
      height: 300px;
    }
    img{
        width: 150px;
    }
    #priceTag{
        font-size: 0.8rem;
        color: black;
    }
    #buyBut{
        width: 120px;
        height: 25px;
        margin-top: 8px;
        font-weight: 400;
        font-size: 0.6rem;
    }
    #buyBut:hover + #AddDrop, #AddDrop:hover{
        display: none;
    }
    
    }
    @media screen and (max-width: 450px) {
        .itemHolder{
            width: 280px;
            height: 395px;
    }
    img{
        width: 230px;
    }
    #priceTag{
        font-size: 1rem;
        color: black;
    }
    #buyBut{
        width: 160px;
        height: 40px;
        font-weight: 600;
        font-size: 0.8rem;
    }

    }
</style>