<template>
    <div id="comp-holder">
      <CarouselMain />
      <div class="ItemsHeaderText">
        <h1>Vyzkoušej naše<br><span id="StandOut">bestsellery</span></h1>
      </div>
      <div>
        <div id="ItemViewHolder">
          <ItemSmallView v-for="item in products_vals" :itemName="item[0].name" :itemPrice="item[0].price" :itemDescription="item[0].description" :imgUrl="item[0].imgurl" :itemStock="item[0].stock" :itemNew="item[0].new" :itemId="item[0].itemId" :key="item.pk"/>
        </div>
        <button v-on:click="() => { this.n = this.n + this.diff; getResponse(); }" id="ShowMoreBtn">Zobrazit více</button>
      </div>

      <ActionDisplay />

    </div>
  </template>
  
  <script>
  import ItemSmallView from '@/components/ItemSmallView.vue';
  import CarouselMain from '@/components/CarouselMain.vue';
  import ActionDisplay from '@/components/ActionDisplay.vue';
  import axios from 'axios';
  
  export default{
    name: "HomePage",
    components: {
      ItemSmallView,
      CarouselMain,
      ActionDisplay
    },
    data(){
      return {
        products_vals: {},
        len_prod: 0,
        diff: 8,
        n: 8,
      }
    },
    beforeMount() 
    {
      this.getResponse(this.n);

    },
    methods: {
        async getResponse(){
             var response = (await axios.get('http://130.61.53.57/api/Sortiment/')).data;
             var result = [];

             for(var i in response)
             {
                result.push([response[i].fields]);
                this.products_vals = result.slice(0, this.n);
             }
             this.len_prod = result.length;
             if(this.len_prod <= this.n){
                document.getElementById('ShowMoreBtn').style.display = 'none';
             }
        },
    }
  }
  
  
  </script>
  
  <style>
  @font-face {
    font-family: 'SansitaOne';
    src: URL('@/assets/SansitaOne.ttf') format('truetype');
  }
  
  #StandOut{
    color: rgb(44, 166, 44)
  }

  #ShowMoreBtn{
    width: 250px;
    margin-left: auto;
    margin-right: auto;
    padding: 20px;
    border-radius: 50px;
    background-color: olivedrab;
    border: 0px;
    font-size: 1.1rem;
    font-weight: 600;
    color: white;
    display: block;
    cursor: pointer;
    transition: 0.2s;
    margin-bottom: 50px;
  }
  
  #ShowMoreBtn:hover{
    background-color: chocolate;
  }

  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
  }
  
  #ItemViewHolder{
    width: 1200px;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 50px;
  }
  
  body{
    padding:0;
  }
  
  .ItemsHeaderText{
    margin-top: 2rem;
    margin-bottom: 2rem;
    text-align: center;
    font-family: "SansitaOne";
  }
  
  @media screen and (max-width: 1200px) {
    #ItemViewHolder{
      width: 900px;
  
  }
  }
  @media screen and (max-width: 900px) {
    #ItemViewHolder{
      width: 600px;
  
  }
  }
  @media screen and (max-width: 600px) {
    #ItemViewHolder{
      width: 450px;
  }
  }
  @media screen and (max-width: 450px) {
    #ItemViewHolder{
      width: 300px;
  }
  }
</style>
