<template>
    <div class="SortHolder">
        <div class="FilterHolder">
            <div id="holder1">
                <button @click="clicked('but1'); filter(3)" id="but1" class="left-rad"> Nejprodávanější</button>
                <button @click="clicked('but2'); filter(2)" id="but2" class="left-bor right-bor"> Nejdražší</button>
                <button @click="clicked('but3'); filter(1)" id="but3" class="right-rad"> Nejlevnější</button>
            </div>
            <div class="productsHolder">
                   <ItemSmallView v-for="item in products_vals" :itemName="item[0].name" :itemPrice="item[0].price" :itemDescription="item[0].description" :imgUrl="item[0].imgurl" :itemStock="item[0].stock" :itemNew="item[0].new" :itemId="item[0].itemId" :key="item.pk"/>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios'
    import ItemSmallView from '@/components/ItemSmallView.vue';

    export default{
        name:"SortimentSite",
        components: {
            ItemSmallView,
        },
        data(){
            return {
                products_vals: {},
            }
        },
        beforeMount() {
            this.getResponse().then(response => {
                var result = [];

                for(var i in response)
                    result.push([response[i].fields]);
                console.log(result)
                this.products_vals = result;
            }).catch(e => {
                console.log(e);
            });;
        },
        methods: {
            async getResponse(){
             var resp = (await axios.get('http://130.61.53.57/api/Sortiment/'));
             console.log(resp);
             return(resp.data);
            },
            clicked(but){
                if(document.getElementById(but).style.backgroundColor == "olivedrab"){
                    this.resetbgs();
                    return;
                }else{
                    this.resetbgs();
                    document.getElementById(but).style.backgroundColor = "olivedrab";
                }
            },
            resetbgs(){
                document.getElementById("but1").style.backgroundColor = "rgba(128, 128, 0, 0.4)";
                document.getElementById("but2").style.backgroundColor = "rgba(128, 128, 0, 0.4)";
                document.getElementById("but3").style.backgroundColor = "rgba(128, 128, 0, 0.4)";
            },
            filter(type){
                this.getResponse().then(response => {
                var result = [];

                for(var i in response)
                    result.push([response[i].fields]); 
                    if(type == 1){
                        var sorted = this.quickSort(result);
                        this.products_vals = sorted;
                    }else if(type == 2){
                        var sorted = this.quickSort(result);
                        this.products_vals = sorted.reverse();
                    }else if(type == 3){
                        var sorted = this.quickSortPopularity(result);
                        this.products_vals = sorted.reverse();
                    }
                })
            },
            quickSort(arr){
                if (arr.length <= 1) {
                    return arr;
                }

                let pivot = arr[0][0].price;
                let leftArr = [];
                let rightArr = [];

                for (let i = 1; i < arr.length; i++) {
                    if (arr[i][0].price < pivot) {
                        leftArr.push(arr[i]);
                    } else {
                        rightArr.push(arr[i]);
                    }
                }
                pivot = arr[0];

                return [...this.quickSort(leftArr), pivot, ...this.quickSort(rightArr)];
            },
            quickSortPopularity(arr){
                if (arr.length <= 1) {
                    return arr;
                }

                let pivot = arr[0][0].popular;
                let leftArr = [];
                let rightArr = [];

                for (let i = 1; i < arr.length; i++) {
                    if (arr[i][0].popular < pivot) {
                        leftArr.push(arr[i]);
                    } else {
                        rightArr.push(arr[i]);
                    }
                }
                pivot = arr[0];

                return [...this.quickSortPopularity(leftArr), pivot, ...this.quickSortPopularity(rightArr)];
            }
        },
    }
</script>


<style scoped>
    .productsHolder{
        width: 1200px;
        margin:auto;
        margin-top: 50px;
        display: flex;
        flex-direction: row;
        flex-wrap:wrap;
    }

    .SortHolder{
        width: 100%;
    }

    .FilterHolder{
        padding:25px;
    }

    .left-bor{
        border-left: solid 1px rgba(0, 0, 0, 0.3) !important;
    }
    .right-bor{
        border-right: solid 1px rgba(0, 0, 0, 0.3) !important;
    }

    #holder1{
        display: flex;
        flex-flow: row;
        width: 450px;
        justify-content: center;
        height: 60px;
        border-radius: 50%;
        margin-left: 10%;
    }
    #holder1 button{
        margin-bottom: 10px;
        background: transparent;
        border: none;
        width: 150px;
        height: 60px;
        cursor: pointer;
        transition: 0.2s;
        background-color: rgba(128, 128, 0, 0.4);
        font-weight: 600;
    }
    .right-rad{
        border-bottom-right-radius: 30px;
        border-top-right-radius: 30px;
        border-left: solid 1px rgba(0, 0, 0, 0.3) !important;
    }
    .left-rad{
        border-bottom-left-radius: 30px;
        border-top-left-radius: 30px;
        border-right: solid 1px rgba(0, 0, 0, 0.3) !important;
    }
    #holder1 button:hover{
        background-color: rgba(106, 142, 35, 0.75) !important;

    }
    @media screen and (max-width: 1200px) {
    .productsHolder{
      width: 900px;
  
  }
  }
  @media screen and (max-width: 900px) {
    .productsHolder{
      width: 600px;
  
  }
  }
  @media screen and (max-width: 600px) {
    .productsHolder{
      width: 450px;
  }
  .FilterHolder{
    padding: 10px;
  }

  #holder1{
        width: 300px;
        height: 50px;
        margin-left: auto;
        margin-right: auto;
    }
   #holder1 button{
    margin-top: 10px;
        margin-bottom: 5px;
        width: 100px;
        height: 40px;
        font-weight: 600;
        font-size: 0.6rem;
    }
  }
  @media screen and (max-width: 450px) {
    .productsHolder{
      width: 300px;
  }
  }
</style>
