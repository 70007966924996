import { createRouter, createWebHistory } from "vue-router";
import ItemSite from "@/views/ItemSite.vue";
import HomePage from "@/views/HomePage.vue";
import O_Nas from "@/views/O_nas.vue";
import AkceSite from "@/views/Site_Akce.vue";
import SortimentSite from "@/views/Site_Sortiment.vue";
import CartView from "@/views/CartView.vue";


const router = createRouter({
    history: createWebHistory(),
    mode: 'history',
    routes : [
        {
            path:'/',
            component: HomePage
        },
        {
            path:'/ItemSite',
            component: ItemSite
        },
        {
            path:'/Onas',
            component: O_Nas
        },
        {
            path:'/Historie',
            component: AkceSite
        },
        {
            path:'/Sortiment',
            component: SortimentSite
        },
        {
            path:'/ItemSite/:productId/:amm',
            name:"ItemSiteViewState",
            component: ItemSite
        },
        {
            path:'/Cart',
            component: CartView
        }
    ]

})

export default router
