<template>
    <div>
        <div class="carousel">

        </div>
        <div class="infoHolder">
            <div class="infoBlock">
                <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="none" viewBox="0 0 24 24">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 7h6l2 4m-8-4v8m0-8V6a1 1 0 0 0-1-1H4a1 1 0 0 0-1 1v9h2m8 0H9m4 0h2m4 0h2v-4m0 0h-5m3.5 5.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0Zm-10 0a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0Z"/>
                </svg>

                <h3>
                    doprava zdarma pro každou objednávku nad 1000kč
                </h3>
            </div>

            <div class="infoBlock">
                <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="none" viewBox="0 0 24 24">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m8.032 12 1.984 1.984 4.96-4.96m4.55 5.272.893-.893a1.984 1.984 0 0 0 0-2.806l-.893-.893a1.984 1.984 0 0 1-.581-1.403V7.04a1.984 1.984 0 0 0-1.984-1.984h-1.262a1.983 1.983 0 0 1-1.403-.581l-.893-.893a1.984 1.984 0 0 0-2.806 0l-.893.893a1.984 1.984 0 0 1-1.403.581H7.04A1.984 1.984 0 0 0 5.055 7.04v1.262c0 .527-.209 1.031-.581 1.403l-.893.893a1.984 1.984 0 0 0 0 2.806l.893.893c.372.372.581.876.581 1.403v1.262a1.984 1.984 0 0 0 1.984 1.984h1.262c.527 0 1.031.209 1.403.581l.893.893a1.984 1.984 0 0 0 2.806 0l.893-.893a1.985 1.985 0 0 1 1.403-.581h1.262a1.984 1.984 0 0 0 1.984-1.984V15.7c0-.527.209-1.031.581-1.403Z"/>
                    </svg>
                <h3>
                    záruka 100% kvality každého našeho produktu
                </h3>
            </div>

            <div class="infoBlock">
                <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="none" viewBox="0 0 24 24">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15.583 8.445h.01M10.86 19.71l-6.573-6.63a.993.993 0 0 1 0-1.4l7.329-7.394A.98.98 0 0 1 12.31 4l5.734.007A1.968 1.968 0 0 1 20 5.983v5.5a.992.992 0 0 1-.316.727l-7.44 7.5a.974.974 0 0 1-1.384.001Z"/>
                </svg>

                <h3>
                    každý čaj pochází z gruzínských čajových plantáží
                </h3>
            </div>
        </div>
    </div>
</template>

<script>

export default {
name: 'CarouselMain',
methods: {
    getImage(img){
        return require(`@/assets/${img}.png`)
    }
}
}
</script>


<style scoped>
    .carousel{
        margin-left: auto;
        margin-right: auto;
        width: 1200px;
        height: 500px;
        border: 1px solid rgba(0, 0, 0, 0.4);
        margin-top: 2rem;
        margin-bottom: 2rem;
        background-size: cover;
    }
    .infoHolder{
        margin-left: auto;
        margin-right: auto;
        width: 1200px;
        display: flex;
        flex-wrap: wrap;
        grid-template-columns: auto auto auto;
        padding-bottom: 1rem;
        margin-bottom: 1rem;
        border-bottom: solid 2px rgba(0, 0, 0, 0.15);
    }
    .infoBlock{
        text-align: left;
        color:black;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        min-width: 200px;
    }
    h3{
        padding-top: 0.5rem;
        font-size: 0.9rem;
        font-weight: 400;
        margin-left: 1.2rem;
        margin-bottom: 20px;
        width: 60%;
        color:rgba(0, 0, 0, 0.8);
    }

    @media screen and (max-width: 1200px) {
        .carousel{
            width: 98%;
        }
        .infoHolder{
            width: 98%;
            justify-content: center;
        }
    }
    @media screen and (max-width: 900px) {
        h3{
            font-size: 1.3rem;
            font-weight: 600;
            width: auto;
        }
        .infoBlock{
            width: 100%;
            justify-content: center;
        }
    }
    @media screen and (max-width: 680px) {
        h3{
            font-size: 1rem;
            font-weight: 400;
            padding-right: 5px;
            width: auto;
        }
    }

</style>